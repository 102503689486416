<template>
  <CRow>
    <CCol sm="12" lg="6">
      <CCard>
        <CCardHeader>Network Bonus Earned</CCardHeader>
        <CCardBody>
            <CChartLineSimple pointed border-color="warning"/>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol sm="12" lg="6">
    <CCard>
        <CCardHeader>Member Profile</CCardHeader>
        <CCardBody>
          <CRow style="margin-top:0.2rem;">
            <CCol sm="3">Username</CCol>
            <CCol sm="8">{{this.data_user.member_username}}</CCol>
          </CRow>
          <hr>
          <CRow style="margin-top:0.2rem;">
            <CCol sm="3">Name</CCol>
            <CCol sm="8">{{this.data_user.member_name}}</CCol>
          </CRow>
          <hr>
          <CRow style="margin-top:0.2rem;">
            <CCol sm="3">Mobile No.</CCol>
            <CCol sm="8">+{{this.data_user.member_mobile_no}}</CCol>
          </CRow>
          <hr>
          <CRow style="margin-top:0.2rem;">
            <CCol sm="3">Email Address</CCol>
            <CCol sm="8">{{this.data_user.member_email}}</CCol>
          </CRow>
          <hr>
          <CRow style="margin-top:0.2rem;">
            <CCol sm="3">Package Rank</CCol>
            <CCol sm="8">{{this.data_user.package_rank}}</CCol>
          </CRow>
          <hr>
          <CRow style="margin-top:0.2rem;">
            <CCol sm="3">Bonus Rank</CCol>
            <CCol sm="8">{{this.data_user.bonus_rank}}</CCol>
          </CRow>
          <hr>
          <CRow style="margin-top:0.2rem;">
            <CCol sm="3">Register Date</CCol>
            <CCol sm="8">{{this.data_user.register_date}}</CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios';
import { CChartLineSimple, CChartBarSimple } from '../charts/index.js'

export default {
  name: 'WidgetProfiles',
  components: { CChartLineSimple, CChartBarSimple },
  data(){
    return{
      urlApi:this.$store.state.urlApi,
      data_user:[]
    }
  },
  mounted(){
    axios.get(`${this.urlApi}/api/user-details/${localStorage.getItem('id')}`).then(response => {
      this.data_user = response.data
    })
  }
}
</script>
