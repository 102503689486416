<template>
  <CRow>
    <CCol sm="12" lg="6">
      <CWidgetDropdown color="primary" :header="this.data_user.cloudcash_balance_wallet +' '+ 'CC'" text="Cloud Cash Wallet">
        <template #default>
          <CIcon name="cil-dollar"/>
        </template>
        <template #footer>
          <CRow>
            <CCol align="center">
              <CButton
                color="primary"
                class="m-2"
                @click="withdrawCloudCash()"
              >
              WITHDRAW CLOUD CASH <CIcon name="cil-cloud-download"/>
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CWidgetDropdown>
    </CCol>
    <CCol sm="12" lg="6">
      <CWidgetDropdown color="info" :header="this.data_user.bitcoin_balance_wallet +' '+ 'BTC'" text="Bitcoin Wallet">
        <template #default>
          <CIcon name="cib-btc"/>
        </template>
        <template #footer>
          <CRow>
            <CCol align="center">
              <router-link to="/mining-earning">
              <CButton
                color="info"
                class="m-2"
              >
              Mining Earning <CIcon name="cil-money"/>
              </CButton>
              </router-link>
            </CCol>
            <CCol align="center">
              <CButton
                color="info"
                class="m-2"
                @click="withdrawBitCoin()"
              >
              WITHDRAW <CIcon name="cil-cloud-download"/>
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CWidgetDropdown>
    </CCol>
    <CModal
      :title="titleWithdraw"
      color="primary"
      :show.sync="warningModal"
    >
    <CForm >
        <CRow>
          <CCol col="12">
            <CInput
              label="Your Ballance"
              placeholder="Your Ballance"
              v-model="currentAmount"
              disabled
            />
          </CCol>
          <CCol col="12">
            <CInput
              label="Total Withdraw"
              placeholder="Total Withdraw"
              v-model="totalWithdraw"
              onkeydown='return (event.which >= 48 && event.which <= 57) || event.which == 8 || event.which == 46'
              v-on:change="keymonitor"
              :maxlength="8"
              type="text"
              readonly
            >
              <template #prepend>
                <CButton color="success" @click="gellAllBallance">Ambil Semua</CButton>
              </template>
              <template #append>
                <CButton color="primary" @click="submitBallace">Submit</CButton>
              </template>
            </CInput>
            <p v-if="showMassageCount" style="color:red">*Jumlah kurang dari 8 digit</p>
            <p v-if="showMassageBallance" style="color:red">*Jumlah Melebihi Uang Anda</p> <br>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12">
            <p><b>*{{massageWithdraw}}</b></p>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="6">
            <CButton color="danger" class="px-4" @click="kembali">Cancel</CButton>
          </CCol>
          <CCol col="6" align="right">
            <CButton color="primary" class="px-4" @click="hubungiAdmin" v-if="hideButtonHubungiAdmin">Hubungi Admin</CButton>
          </CCol>
        </CRow>
      </CForm>
    </CModal>
  </CRow>
</template>

<script>
import axios from 'axios';
import { CChartLineSimple, CChartBarSimple } from '../charts/index.js'

export default {
  name: 'WidgetsDropdown',
  components: { CChartLineSimple, CChartBarSimple },
  data () {
    return {
      titleWithdraw:null,
      urlApi:this.$store.state.urlApi,
      warningModal: false,
      data_user:[],
      massageWithdraw:null,
      totalWithdraw:null,
      withdrawData:{
        id_user:null,
        username:null,
        withdraw_cloudcash_balance_wallet:null,
        withdraw_bitcoin_balance_wallet:null,
        date_withdraw:null,
        latitude_withdraw:null,
        longitude_withdraw:null,
        city_withraw:null,
        country_withdraw:null,
        ip_login:null,
        status:null
      },
      withdrawType:null,
      getIp:[],
      currentAmount:null,
      hideButtonHubungiAdmin:false,
      showMassageCount:false,
      showMassageBallance:false
    }
  },
  mounted(){
    axios.get(`${this.urlApi}/api/user-details/${localStorage.getItem('id')}`).then(response => {
      this.data_user = response.data
    })

    axios.get(`https://api.ipdata.co?api-key=ae1682e37460c5f1006fd9414ba8bcdc877c2bb6e50ec99372a5f978`).then(response => {
      this.getIp = response.data
    })
  },
  methods:{
    currency(value){
      return new Intl.NumberFormat('de-DE', { style: 'decimal', currency: 'XBT' }).format(value)
    },

    keymonitor(event) {
      if(this.withdrawType == 'cloudcash'){
        this.totalWithdraw = this.totalWithdraw
      } else{
        this.totalWithdraw = parseFloat(event.replace('','.'))
      }
    },

    withdrawCloudCash(){
      this.warningModal = true
      this.titleWithdraw = "Withdraw Cloud Cash"
      this.massageWithdraw = "Untuk Menarik Cloud Cash, Klik Button Dibawah ini Untuk Menghubungi Admin"
      this.currentAmount = this.data_user.cloudcash_balance_wallet

      this.totalWithdraw = this.data_user.cloudcash_balance_wallet


      this.withdrawType = 'cloudcash'
    },
    withdrawBitCoin(){
      this.warningModal = true
      this.titleWithdraw = "Withdraw Bitcoin"
      this.massageWithdraw = "Untuk Menarik Bitcoin, Klik Button Dibawah ini Untuk Menghubungi Admin"
      this.currentAmount = this.data_user.bitcoin_balance_wallet

      this.totalWithdraw = this.data_user.bitcoin_balance_wallet


      this.withdrawType = 'bitcoin'
    },

    countDecimals(value) {
        if(Math.floor(value) === value) return 0;
        return value.toString().split(".")[1].length || 0; 
    },

    submitBallace(){

      if(this.withdrawType == 'bitcoin'){
        if(this.totalWithdraw>this.data_user.bitcoin_balance_wallet){
            this.showMassageBallance = true
            this.hideButtonHubungiAdmin = true
          } else {
            this.showMassageBallance = false
            this.hideButtonHubungiAdmin = true
          }
      } else {
        if(this.totalWithdraw>this.data_user.cloudcash_balance_wallet){
            this.showMassageBallance = true
            this.hideButtonHubungiAdmin = true
          } else {
            this.showMassageBallance = false
            this.hideButtonHubungiAdmin = true
          }
      }

    },

    gellAllBallance(){
      if(this.withdrawType == 'bitcoin'){
         this.totalWithdraw = this.data_user.bitcoin_balance_wallet
      } else {
        this.totalWithdraw = this.data_user.cloudcash_balance_wallet
      }
    },

    kembali(){
      this.totalWithdraw = null
      this.warningModal = false
      this.hideButtonHubungiAdmin = false
      this.showMassageCount = false
    },
    hubungiAdmin(){
      if(this.withdrawType == 'cloudcash'){
        this.withdrawData.withdraw_cloudcash_balance_wallet = this.totalWithdraw
        this.withdrawData.withdraw_bitcoin_balance_wallet = 0
      } else if(this.withdrawType == 'bitcoin'){
        this.withdrawData.withdraw_cloudcash_balance_wallet = 0
        this.withdrawData.withdraw_bitcoin_balance_wallet = this.totalWithdraw
      }

      let date = new Date(this.getIp.time_zone.current_time)

      let bulan = date.getMonth()+1

      
      this.withdrawData.id_user = this.data_user.id
      this.withdrawData.username = this.data_user.member_username
      this.withdrawData.date_withdraw = date.getFullYear()+'-'+bulan+'-'+date.getDate()+' '+date.getHours()+':'+date.getMinutes()+':'+date.getSeconds()
      this.withdrawData.latitude_withdraw = this.getIp.latitude
      this.withdrawData.longitude_withdraw = this.getIp.longitude
      this.withdrawData.city_withraw = this.getIp.city
      this.withdrawData.country_withdraw = this.getIp.country_name
      this.withdrawData.ip_login = this.getIp.ip
      this.withdrawData.status = 1

      axios.get(`${this.urlApi}/api/log-withdraw/get/${this.data_user.member_username}`).then(response => {
        if(response.data === undefined || response.data.length == 0){
          axios.post(`${this.urlApi}/api/log-withdraw`, this.withdrawData).then(response => {
            location.href = ('http://wa.me/+6281294809792?text=Hallo%2C+saya%0D%0AUsername%3A+'+ this.data_user.member_username +'+%0D%0ANama%3A+ '+ this.data_user.member_name +'+%0D%0Aingin+melakukan+'+ this.titleWithdraw +'+di+BIOCLOUD+AURUM')
            // location.href = ('http://wa.me/+6282114902056?text=Hallo%2C+saya%0D%0AUsername%3A+'+ this.data_user.member_username +'+%0D%0ANama%3A+ '+ this.data_user.member_name +'+%0D%0Aingin+melakukan+'+ this.titleWithdraw +'+di+BIOCLOUD+AURUM')
          })
        } else {
          if(response.data[0].status == 1){
            alert('Mohon Menunggu Sampai Diproses Admin')
          } else {
            axios.post(`${this.urlApi}/api/log-withdraw`, this.withdrawData).then(response => {
              location.href = ('http://wa.me/+6281294809792?text=Hallo%2C+saya%0D%0AUsername%3A+'+ this.data_user.member_username +'+%0D%0ANama%3A+ '+ this.data_user.member_name +'+%0D%0Aingin+melakukan+'+ this.titleWithdraw +'+di+BIOCLOUD+AURUM')
              // location.href = ('http://wa.me/+6282114902056?text=Hallo%2C+saya%0D%0AUsername%3A+'+ this.data_user.member_username +'+%0D%0ANama%3A+ '+ this.data_user.member_name +'+%0D%0Aingin+melakukan+'+ this.titleWithdraw +'+di+BIOCLOUD+AURUM')
            })
          }
        }
      })

    }
  }
}
</script>
