<template>
  <CRow>
    <CCol sm="12" lg="3">
      <CCard>
        <CCardBody>
            <CRow>
                <CCol sm="12" align="center">
                    <img src="../../assets/img/cap/cap-10.png" width="200">
                </CCol>
                <CCol sm="12" align="center">
                    <h6>OPEN-ENDED CONTRACT</h6>
                </CCol>
                <CCol sm="12" align="center">
                    <h4>500</h4> cc
                </CCol>
                <CCol sm="12" align="center">
                    <CButton
                        color="info"
                        class="m-2"
                    >
                    PURCHASE
                    </CButton>
                </CCol>
            </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol sm="12" lg="3">
    <CCard>
        <CCardBody>
            <CRow>
                <CCol sm="12" align="center">
                    <img src="../../assets/img/cap/cap-20.png" width="200">
                </CCol>
                <CCol sm="12" align="center">
                    <h6>OPEN-ENDED CONTRACT</h6>
                </CCol>
                <CCol sm="12" align="center">
                    <h4>1000</h4> cc
                </CCol>
                <CCol sm="12" align="center">
                    <CButton
                        color="info"
                        class="m-2"
                    >
                    PURCHASE
                    </CButton>
                </CCol>
            </CRow>
        </CCardBody>
      </CCard>
    </CCol>
        <CCol sm="12" lg="3">
      <CCard>
        <CCardBody>
            <CRow>
                <CCol sm="12" align="center">
                    <img src="../../assets/img/cap/cap-30.png" width="200">
                </CCol>
                <CCol sm="12" align="center">
                    <h6>OPEN-ENDED CONTRACT</h6>
                </CCol>
                <CCol sm="12" align="center">
                    <h4>5000</h4> cc
                </CCol>
                <CCol sm="12" align="center">
                    <CButton
                        color="info"
                        class="m-2"
                    >
                    PURCHASE
                    </CButton>
                </CCol>
            </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol sm="12" lg="3">
    <CCard>
        <CCardBody>
            <CRow>
                <CCol sm="12" align="center">
                    <img src="../../assets/img/cap/cap-40.png" width="200">
                </CCol>
                <CCol sm="12" align="center">
                    <h6>OPEN-ENDED CONTRACT</h6>
                </CCol>
                <CCol sm="12" align="center">
                    <h4>10000</h4> cc
                </CCol>
                <CCol sm="12" align="center">
                    <CButton
                        color="info"
                        class="m-2"
                    >
                    PURCHASE
                    </CButton>
                </CCol>
            </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>

export default {
  name: 'WidgetEarning',
}
</script>
